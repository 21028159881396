import * as React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import BuyingForm from '../components/form/BuyingForm'

const CurrentDev = () => {
  return (
    <Layout>
      <Seo title="Current Dev" description="Gray and Sons Jewelry | Used rolex" noindex={true} />
      <BuyingForm />
    </Layout>
  )
}

export default CurrentDev
